import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCursorHover } from '../../../features/cursorSlice'
import * as adminService from '../../../utilities/admin-service'
import './AdminGallery.css'

export function PendingGallery({ pendingSubs, handleSort }) {
  const dispatch = useDispatch()
  return (
    <div className="admin-gallery">
      {pendingSubs.map((submission) => (
        <div className="admin-sub-container" key={submission._id}>
          <img
            className="admin-sub-img"
            src={submission.imagePath}
            alt="submission"
          />
          <h6>
            <u>
              <span style={{ fontWeight: 'bold' }}>Types</span>
            </u>
            :{' '}
            {submission.types.length !== 0
              ? submission.types.join(', ')
              : 'n/a'}
          </h6>
          <h6>
            <u>
              <span style={{ fontWeight: 'bold' }}>Location</span>
            </u>
            :{' '}
            {submission.locationDescription
              ? submission.locationDescription
              : 'n/a'}
          </h6>
          <h6>
            <u>
              <span style={{ fontWeight: 'bold' }}>Comments</span>
            </u>
            : {submission.comments ? submission.comments : 'n/a'}
          </h6>
          <div className="classify-btns">
            <button
              className="styledbtn"
              onClick={() => handleSort(submission._id, true)}
              onMouseEnter={() => dispatch(setCursorHover(true))}
              onMouseLeave={() => dispatch(setCursorHover(false))}
            >
              Approve
            </button>
            <button
              className="styledbtn"
              onClick={() => handleSort(submission._id, false)}
              onMouseEnter={() => dispatch(setCursorHover(true))}
              onMouseLeave={() => dispatch(setCursorHover(false))}
            >
              Deny
            </button>
          </div>
        </div>
      ))}
    </div>
  )
}

export function ApprovedGallery({ approvedSubs }) {
  return (
    <div className="admin-gallery">
      {approvedSubs.map((submission) => (
        <div className="admin-sub-container" key={submission._id}>
          <img
            className="admin-sub-img"
            src={submission.imagePath}
            alt="submission"
          />
          <h6>
            <u>
              <span style={{ fontWeight: 'bold' }}>Types</span>
            </u>
            :{' '}
            {submission.types.length !== 0
              ? submission.types.join(', ')
              : 'n/a'}
          </h6>
          <h6>
            <u>
              <span style={{ fontWeight: 'bold' }}>Location</span>
            </u>
            :{' '}
            {submission.locationDescription
              ? submission.locationDescription
              : 'n/a'}
          </h6>
          <h6>
            <u>
              <span style={{ fontWeight: 'bold' }}>Comments</span>
            </u>
            : {submission.comments ? submission.comments : 'n/a'}
          </h6>
        </div>
      ))}
    </div>
  )
}

export function DeniedGallery({ deniedSubs, setDeniedSubs }) {
  const [showModal, setShowModal] = useState(false)
  const [selectedSubmission, setSelectedSubmission] = useState(null)
  const [showDeleteMessage, setShowDeleteMessage] = useState(false)
  const [deleteMessage, setDeleteMessage] = useState('')
  const dispatch = useDispatch()

  function handleDeleteClick(submissionId) {
    setSelectedSubmission(submissionId)
    setShowModal(true)
    setShowDeleteMessage(false)
  }

  function closeModal() {
    setShowModal(false)
    setShowDeleteMessage(false)
  }

  async function handleDelete() {
    try {
      await adminService.handleDelete(selectedSubmission)
      // filter out deleted submission from deniedSubs state
      const updatedDeniedSubs = deniedSubs.filter(
        (sub) => sub._id !== selectedSubmission
      )
      // refresh denied subs list to remove the now-deleted mascot submission
      setDeniedSubs(updatedDeniedSubs)

      // set delete message on modal and display!
      setDeleteMessage('Mascot successfully deleted :D')
      setShowDeleteMessage(true)

      // self-close modal after 2 seconds
      setTimeout(() => {
        closeModal()
      }, 2000)
    } catch (error) {
      console.error('Error deleting submission:', error)
      // use delete message to provide feedback upon failure of sub deletion
      setDeleteMessage('Error deleting mascot!')
      setShowDeleteMessage(true)
    }
  }

  return (
    <div className="admin-gallery">
      {showModal && (
        // MODAL FOR DELETING SUBS
        <div className="delete-modal-overlay" onClick={closeModal}>
          <div
            className="delete-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            {showDeleteMessage ? (
              <h5>{deleteMessage}</h5>
            ) : (
              <>
                <h5>
                  Are you sure you want to delete this mascot?
                  <br />
                  This is irreversible!
                </h5>
                <div className="delete-modal-btns">
                  <button className="styledbtn" onClick={handleDelete}>
                    Delete Mascot!
                  </button>
                  <button className="styledbtn" onClick={closeModal}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {deniedSubs.map((submission) => (
        <div className="admin-sub-container" key={submission._id}>
          <img
            className="admin-sub-img"
            src={submission.imagePath}
            alt="submission"
          />
          <h6>
            <u>
              <span style={{ fontWeight: 'bold' }}>Types</span>
            </u>
            :{' '}
            {submission.types.length !== 0
              ? submission.types.join(', ')
              : 'n/a'}
          </h6>
          <h6>
            <u>
              <span style={{ fontWeight: 'bold' }}>Location</span>
            </u>
            :{' '}
            {submission.locationDescription
              ? submission.locationDescription
              : 'n/a'}
          </h6>
          <h6>
            <u>
              <span style={{ fontWeight: 'bold' }}>Comments</span>
            </u>
            : {submission.comments ? submission.comments : 'n/a'}
          </h6>
          <div className="classify-btns">
            <button
              className="styledbtn"
              id="delete"
              onClick={() => handleDeleteClick(submission._id)}
              onMouseEnter={() => dispatch(setCursorHover(true))}
              onMouseLeave={() => dispatch(setCursorHover(false))}
            >
              DELETE MASCOT!
            </button>
          </div>
        </div>
      ))}
    </div>
  )
}

// 404 ERROR ON MASCOT DELETION!!! FIX FIX FIX!!
